//
export const NoEnoughBalance = 100101

export const ExpireOrderCantRenew = 201

// MailX
export const UserNameTaken = 2001

export const FreeVersionCantUseThisUserName = 2002
export const TooManyFreeVersionOrder = 2003

export const UserNotExists = 601
export const PasswordWrong = 602

export const CurrentPasswordWrong = 102
export const NewPasswordRepeatWrong = 103

// Register
export const InviteCodeWrong = 801
export const EmailWrong = 802
export const PasswordTooShort = 803
export const EmailUsed = 804

// Password
export const EmailNotExist = 901
export const LinkExpired = 902
